<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-2/3 mb-6 md:pr-2">
        <img src="@/assets/Resources/Blog/mulwala-min.jpg" />
      </div>
      <div class="w-full mb-6">
        <TitleComponent title="Mulwala Redevelopment Project (MRP)" />
        <Paragraph
          ><p>
            SMS was involved in a major project in South East Australia called
            the Mulwala Redevelopment Project. Starting in 2007 and stretching
            through till 2015, over 1000 days were worked without a lost time
            injury. This was a project for the Australian Department of Defense
            to modernize the production of single base propellants. The project
            involved the design and development of a new manufacturing process
            as well as construction of the new facilities at the Defense owned,
            company operated site at Mulwala, NSW Australia. In addition,
            startup and low rate initial production of the plant was required,
            all while the existing facility was in operation. The explosive
            safety support included Risk Management, Testing and Facility
            Siting.
          </p>
          <p>
            <strong>RISK MANAGEMENT</strong><br />
            Safety Management Services (SMS) provided Explosives Safety Support
            for the MRP from the concept phase through design, construct and
            commissioning of the state-of-the-art single base propellant
            manufacturing facility in Mulwala, New South Wales Australia. As the
            facilities were erected and equipment was built, SMS supported the
            efforts through building inspections and equipment specific hazards
            analysis coupled with onsite factory acceptance testing for the
            process equipment. Explosives Safety Reviews were conducted on all
            process equipment and tooling meant to handle energetic material. As
            the processes were brought online, SMS lead the team through the
            Prestart-up Safety Reviews and performed additional Process Hazards
            on all processes, procedures and equipment.<br />
            As well as Process Hazards Analysis and general explosives safety
            support through the design, construction and start up phases, SMS
            provided operational and systems safety support of the operating
            plant to ensure safe operation and development of an industry best
            safety program and culture.
          </p>
          <p>
            <strong>EXPLOSIVES TESTING SUPPORT</strong><br />
            The testing portion of the project required SMS to perform multiple
            tests on nine single-based propellants as follows:
          </p>
          <ol>
            <li>IMR-8208M* (5.56 mm Ball)</li>
            <li>SR-8231* (5.56 mm Blank)</li>
            <li>IMR-5010 (.50 cal Ball)</li>
            <li>Mod SR-8231*(.50 cal Blank)</li>
            <li>RP-36 FS (25 mm, M793)</li>
            <li>M1SP (105 mm Low Zone)</li>
            <li>M1MP (105 mm High Zone)</li>
            <li>M10 (81 mm Mortar)</li>
            <li>BS-NACO (5”/54)</li>
          </ol>
          <p>The tests performed were:</p>
          <ul>
            <li>ABL Impact</li>
            <li>UN 5(a) Cap Sensitivity</li>
            <li>ABL Friction</li>
            <li>NOL Card Gap</li>
            <li>ABL ESD</li>
            <li>Critical Height</li>
            <li>Auto-ignition</li>
            <li>Small Burn</li>
          </ul>
          <p>
            Additional testing was performed based on concerns for elevated
            temperature of the propellant in the air dryer as well as depths and
            confinement of propellant in process. Sensitivity and reactivity
            tests performed included:
          </p>
          <ul>
            <li>ABL Impact at Elevated Temperature</li>
            <li>ABL Friction at Elevated Temperature</li>
            <li>Critical Height at Elevated Temperature</li>
            <li>Critical Height (Less Restrictive)</li>
            <li>Critical Height (Confined)</li>
          </ul>
          <p>
            Safety Management Services, Inc. (SMS) has also completed further
            hazards testing based on concerns for elevated temperature of the
            propellant as well as stacking of bulk bags in the in- process
            storage magazines. Modified elevated temperature critical height,
            and simulated bulk bag storage testing was also performed.
          </p>
          <p>
            <strong>FACILITY SITING SUPPORT</strong><br />
            SMS performed a preliminary facility siting assessment for the new
            and modified Mulwala facilities. The preliminary assessment was in
            support of the proposal effort to the Australian Department of
            Defense. SMS determined the maximum quantities of explosive
            materials allowed in each building based on the separation distance
            between each building under the OPSMAN-3, Safety Principles for the
            Handling of Explosive Ordnance standard, other governing
            regulations, and applicable explosive safety standards.<br />
            Other factors considered were building design/construction, types
            and quantities of explosives in-process and in-storage, and distance
            to adjacent buildings in establishing the placement of buildings on
            a given site such that risks to personnel and facilities are
            minimized.<br />
            The layout development process criteria in order of precedence were:
            safety, environment, efficiency of operation, impact on existing
            facility and neighboring properties, and other.<br />
            The layout of the facilities was developed such that the inhabited
            building distances were taken to the property boundary, to protect
            the operations and facility from any potential development outside
            of the property boundaries and to reduce any impact on residential
            or commercial neighbors.<br />
            A preliminary review of the respective Facility Site Plans and the
            associated types and quantities of materials to be used or handled
            was conducted. From this review an initial risk ranking of the
            operations at the sites was developed. This ranking was based on:
          </p>
          <ol>
            <li>Amount of material being processed</li>
            <li>The number of personnel exposed in the event of an incident</li>
            <li>The potential for consequences off-site</li>
            <li>The location in relation to other facilities</li>
          </ol>
          <p>
            QD arcs were developed for each new facility/building to be erected
            and were based on OPSMAN 3. This included QD arcs of process
            buildings for operations involving explosive ordinance (EO) of HD
            1.1 and HD 1.3 facilities, arcs for inhabited building and public
            traffic route distances and arcs for the existing operational
            facilities.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Mulwala Redevelopment Project",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS provided risk management, testing, and facility siting support for the Mulwala Redevelopment Project (MRP). This 8-year job went over 1000 working days without a time lost injury."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
